var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content margin-top-xl" },
      [
        _c("van-field", {
          ref: "real_name",
          attrs: {
            placeholder: "姓名",
            label: "姓名：",
            autofocus: "",
            "label-align": "right",
            required: "",
            id: "real_name",
          },
          model: {
            value: _vm.real_name,
            callback: function ($$v) {
              _vm.real_name = $$v
            },
            expression: "real_name",
          },
        }),
        _vm._v(" "),
        _c("van-field", {
          attrs: {
            placeholder: "身份证号码",
            type: "number",
            label: "身份证号码：",
            "label-align": "right",
            required: "",
          },
          model: {
            value: _vm.identity_no,
            callback: function ($$v) {
              _vm.identity_no = $$v
            },
            expression: "identity_no",
          },
        }),
        _vm._v(" "),
        _c("van-field", {
          attrs: {
            placeholder: "恒生工号",
            label: "恒生工号：",
            "label-align": "right",
            required: "",
          },
          model: {
            value: _vm.job_no,
            callback: function ($$v) {
              _vm.job_no = $$v
            },
            expression: "job_no",
          },
        }),
        _vm._v(" "),
        _c("van-field", {
          attrs: {
            placeholder: "请输入11位手机号码",
            type: "number",
            label: "手机号码：",
            "label-align": "right",
            required: "",
          },
          model: {
            value: _vm.phone,
            callback: function ($$v) {
              _vm.phone = $$v
            },
            expression: "phone",
          },
        }),
        _vm._v(" "),
        _c(
          "van-field",
          {
            attrs: {
              placeholder: "请输入4位验证码",
              type: "number",
              label: "验证码：",
              "label-align": "right",
              required: "",
            },
            model: {
              value: _vm.captcha,
              callback: function ($$v) {
                _vm.captcha = $$v
              },
              expression: "captcha",
            },
          },
          [
            _c("VerifyCodeBtn", {
              attrs: { slot: "button", type: "bind" },
              slot: "button",
              model: {
                value: _vm.phone,
                callback: function ($$v) {
                  _vm.phone = $$v
                },
                expression: "phone",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "margin-top-xxl" },
          [
            _c(
              "van-button",
              {
                attrs: { size: "large", type: "info", disabled: _vm.disabled },
                on: { click: _vm.bind },
              },
              [_vm._v("提交注册")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "logo margin-top" }, [
        _c("img", {
          attrs: {
            src: require("../../images/login/getheadimg.jpeg"),
            alt: "default_logo",
            width: "80",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-gray  text-lg margin-bottom" }, [
        _vm._v("注册账号，便于下次快捷登录"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }